<template>
  <div class="p-grid p-fluid dashboard">
    <div 
      v-for="rail in wagonsPerRail" 
      :key="rail.id"  
      class="p-col-12 p-md-4 p-xl-2"
		>
			<rails-panel :title="rail.label" :wagons="rail.wagons" />
    </div>
  </div>
</template>

<script>
//import trainJourneysPanel from '../../components/misc/TrainJourneysPanel';
import railsPanel from '../../components/misc/RailsPanel';
import RailTosService from '../../service/tos/RailTosService';
import _ from 'lodash';

export default {
	components: {
		//trainJourneysPanel,
		railsPanel
	},
	data() {
		return {			
			service: null,
			wagonsPerRail: [],
			wagonsData: []
		}
	},
	created() {
		this.service = new RailTosService();
	},
	async mounted(){		
		try {
			this.wagonsData = await this.$api.get(this.$apiConfiguration.TRACCE_FINDTODAYJOURNEYS);
			_.forEach(this.wagonsData, (w) => {
				let rail = _.find(this.wagonsPerRail, (r) => { 
					return r.id == w.binario.id; 
				});
				if(!rail) {
					rail = {
						id: w.binario.id,
						label: w.binario.label,
						wagons: []
					}
					this.wagonsPerRail.push(rail);
				}
				rail.wagons.push({
					id: w.id,
					label: w.label,
					startDate: w.startDate,
					endDate: w.endDate,
					trainIn: w.trenoPartenza,
					trainOut: w.trenoArrivo,
					length: w.lunghezzaConvoglio,
					railPosition: w.posizioneBinario
				});
			});
		} catch(e) {
			console.log(e);
		}
	},
}
</script>